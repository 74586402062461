import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import COLORS from 'src/utils/colors';
import { Input } from '@mui/material';

export const StyledInput = styled(Input)(({ theme }) => ({
  border: `1px solid ${COLORS.grey[50]}`,
  borderRadius: '10px',
  marginTop: '0px !important',

  '::after, ::before': {
    display: 'none !important'
  },

  input: {
    padding: '15.5px 14px',

    '::after, ::before': {
      display: 'none !important'
    }
  },

  [theme.breakpoints.down('sm')]: {
  }
}));
