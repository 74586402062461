import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import {
  login,
  logout,
  getProfileInfo,
  updateProfileInfo,
  updateProfilePassword,
  IUpdateProfileInfo,
  IUpdateProfilePassword
} from '../../services/auth.service';
import { removeToken, setToken } from '../../helpers/token';
import { constants } from '../../constants';
import { RootState } from '../index';
import { callStates, callHouses } from 'src/services/app.service';

export interface StatesInterface {
  id: number,
  code: string,
  name: string
}

export interface HousesInterface {
  id: number,
  maxCubicFeet: number,
  title: string
}

export interface AppState {
  states: StatesInterface[];
  houses: HousesInterface[]
}

const initialState: AppState = {
  states: [],
  houses: []
};

export const getStatesThunk = createAsyncThunk('get/states', async (_, { rejectWithValue }) => {
  try {
    return await callStates()
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getHousesThunk = createAsyncThunk('get/houses', async (_, { rejectWithValue }) => {
  try {
    return await callHouses()
  } catch (err) {
    return rejectWithValue(err);
  }
});


const appSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setStates: (state, action) => {
      state.states = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStatesThunk.fulfilled, (state, { payload }) => {
      state.states = payload;
    });
    builder.addCase(getHousesThunk.fulfilled, (state, { payload }) => {
      state.houses = payload;
    })
  }
});

export const getStatesSelector = (state: RootState) => state.app.states;
export const getHousesSelector = (state: RootState) => state.app.houses;

export const { setStates } = appSlice.actions;



export default appSlice.reducer;
