import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAll,
  getStatistic,
  getOne,
  getFreeMoves,
  getAccepted,
  updateMoveStatusRequest, getClosedMovesRequest, getAllLeads, getLoadBoard,
} from '../../services/move.service';

import { RootState } from '../index';
import { IPagination } from '../../interfaces';
import { IAcceptedMove, IClosedMove, MoveCreateModel, MovePendingModel, MoveStatus } from 'src/models/move.model';
import { getAllAcceptedLeads } from 'src/services/connections.service';

export interface Leads {
  id: number
  partnerId: number
  moveId: number
  totalPrice: string
  cubicFeetPrice: string
  longCarryFee: any
  stairsFee: string
  shuttleServiceFee: any
  fullPackingFee: string
  status: string
  createdAt: string
  updatedAt: string
  partner: Partner
}

export interface Partner {
  id: number
  userId: number
  customerId: string
  email: string
  phone: string
  isActive: boolean
  companyName: string
  companyAddress: string
  dot: number
  googlePlaceId: string
  rating: number
  companyWebsite: string
  createdAt: string
  updatedAt: string
}

export interface ConnectionsState {
  connections: Leads[],
  currentPage: number,
  total: number;
}

const initialState: ConnectionsState = {
  connections: [] as Leads[],
  currentPage: 1,
  total: 0,
};

export const getAllConnections = createAsyncThunk(
  'leads/connections',
  async (query: IPagination & { isInterState?: boolean }) => getAllAcceptedLeads(query)
);

const connectionSlice = createSlice({
  name: 'move',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    clearState: (state) => {
      state.connections = [];
      state.total = 0;
      state.currentPage = 1;
    },
  },
  extraReducers: (builder) => {
    /** GET ALL MOVES * */
    builder.addCase(getAllConnections.fulfilled, (state, action) => {
      state.total = action.payload.total;
      state.connections = action.payload.data;
    });
  }
});

// export const getMoves = (state: RootState) => state.move.moves;

export const { setCurrentPage, clearState } = connectionSlice.actions;

export default connectionSlice.reducer;
