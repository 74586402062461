import { sendRequest } from '../api/axios';
import { IPagination } from '../interfaces/index';

export const getAllReferralLeads = async (query: IPagination) => {
  return sendRequest({
    method: 'GET',
    params: query,
    url: '/admin/referrals',
  });
};
