
export const referralPartnersBoardFiltersFields = [
  {
    title: 'A-Z, Z-A',
    name: 'orderType',
    type: 'select',
    options: [
      { label: 'A-Z', value: 'ASC' },
      { label: 'Z-A', value: 'DESC' },
    ]
  }
]