import { UserModel } from "./user.model";

export enum NotificationType {
  ALL = 'ALL',
  NEW_MOVE = 'NEW_MOVE',
  NEW_USER = 'NEW_USER',
  NEW_PARTNER = 'NEW_PARTNER',
}

export enum NotificationStatus {
  ALL = 'ALL',
  READ = 'READ',
  UNREAD = 'UNREAD',
}

export interface NotificationModel {
  id: string;
  readAt: Date | null;
  type: NotificationType;
  createdAt: Date;
  resource: UserModel;
}
