import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAll,
  getStatistic,
  getOne,
  getFreeMoves,
  getAccepted,
  updateMoveStatusRequest, getClosedMovesRequest, getAllLeads, getLoadBoard
} from '../../services/move.service';

import { RootState } from '../index';
import { IPagination } from '../../interfaces';
import { IAcceptedMove, IClosedMove, MoveCreateModel, MovePendingModel, MoveStatus } from 'src/models/move.model';

interface IStatistic {
  open: number,
  closed: number,
  leadsToday: number,
  leadsTotal: number
}

export interface MoveState {
  move: MoveCreateModel | null,
  moves: MoveCreateModel[],
  pendingMoves: MovePendingModel[],
  acceptedMoves: IAcceptedMove[],
  closedMoves: IClosedMove[],
  currentPage: number,
  total: number;
  statistic: IStatistic;
  errors: any;
}

const initialState: MoveState = {
  move: null,
  moves: [],
  pendingMoves: [],
  acceptedMoves: [],
  closedMoves: [],
  currentPage: 1,
  total: 0,
  statistic: null,
  errors: null,
};


export const getMoveByIdThunk = createAsyncThunk('move/getOne', async (id: string) =>
  getOne(id)
);

export const getAllThunk = createAsyncThunk(
  'move/getAll',
  async (query: IPagination & { isInterState?: boolean }) => getAll(query)
);

export const getAllLeadsThunk = createAsyncThunk(
  'leads/getAll',
  async (query: IPagination & { isInterState?: boolean }) => getAllLeads(query)
);

export const getLoadBoardThunk = createAsyncThunk(
  'loadBoard/getAll',
  async (query: IPagination & { isInterState?: boolean }) => getLoadBoard(query)
);

export const getClosedMovesThunk = createAsyncThunk(
  'move/getClosed',
  async (query: IPagination) => getClosedMovesRequest(query)
);

export const getAcceptedMovesThunk = createAsyncThunk(
  'move/getAccepted',
  async (query: IPagination & { isInterState?: boolean }) => getAccepted(query)
);

export const getStatisticThunk = createAsyncThunk(
    'move/getStatistic',
    async () => getStatistic()
);
export const updateMoveStatusThunk = createAsyncThunk(
    'move/updateStatus',
    async (payload: { moveId: string; bookId: string; status: MoveStatus }) =>
      updateMoveStatusRequest(payload)
);

const moveSlice = createSlice({
  name: 'move',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    clearState: (state) => {
      state.moves = [];
      state.acceptedMoves = [];
      state.total = 0;
      state.currentPage = 1;
      state.errors=null;
    },
  },
  extraReducers: (builder) => {
    /** GET ALL MOVES * */
    builder.addCase(getAllThunk.fulfilled, (state, action) => {
      state.moves = action.payload.data;
      state.total = action.payload.total;
    });
    builder.addCase(getClosedMovesThunk.fulfilled, (state, { payload }) => {
      state.closedMoves = payload.data;
      state.total = payload.total;
    });
    /** GET ALL STATISTIC * */
    builder.addCase(getStatisticThunk.fulfilled, (state, action) => {
      state.statistic = action.payload;
    });
  }
});

export const getMoves = (state: RootState) => state.move.moves;
export const getStaticAction = (state: RootState) => state.move.statistic;
export const getTotal = (state: RootState) => state.move.total;
export const getErrors = (state: RootState) => state.move.errors?.details;
export const getCurrentPage = (state: RootState) => state.move.currentPage;
export const getPendingMoves = (state: RootState) => state.move.pendingMoves;
export const getAcceptedMoves = (state: RootState) => state.move.acceptedMoves;
export const getClosedMoves = (state: RootState) => state.move.closedMoves;

export const { setCurrentPage, clearState } =  moveSlice.actions;

export default moveSlice.reducer;
