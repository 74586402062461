import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../index';

import { IPagination } from '../../interfaces';
import { paginationLimit } from '../../constants';
import { InsuranceModel } from '../../models/insurance.model';
import { destroy, getAll, getOne, IUpdateInsurance, update, create, ICreateInsurance, updateDefault } from '../../services/insurance.service';

interface IUpdateThunk {
  id: string;
  data: IUpdateInsurance;
}

export interface InsuranceState {
  insurances: [] | InsuranceModel[];
  insurance: null | InsuranceModel;
  action: 'CREATED' | 'EDIT' | 'INDEX';
  total: number;
  currentPage: number;
  errors: any;
}

const initialState: InsuranceState = {
  insurances: [],
  insurance: null,
  action: 'INDEX',
  total: 0,
  currentPage: 1,
  errors: null
};

export const createThunk = createAsyncThunk(
  'insurance/create',
  async (data: ICreateInsurance, { rejectWithValue }) => {
    try {
      return await create(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateThunk = createAsyncThunk(
  'insurance/update',
  async (data: IUpdateThunk, { rejectWithValue }) => {
    try {
      return await update(data.id, data.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateDefaultThunk = createAsyncThunk(
  'insurance/update',
  async (id: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // @ts-ignore
      const { insurance } = getState();
       await updateDefault(id);
       dispatch(
        getAllThunk({
          limit: paginationLimit,
          offset: (insurance.currentPage - 1) * paginationLimit
        })
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const destroyThunk = createAsyncThunk(
  'insurance/destroy',
  async (id: string, thunkAPI) => {
    // @ts-ignore
    const { insurance } = thunkAPI.getState();
    await destroy(id);
    thunkAPI.dispatch(
      getAllThunk({
        limit: paginationLimit,
        offset: (insurance.currentPage - 1) * paginationLimit
      })
    );
  }
);

export const getAllThunk = createAsyncThunk(
  'insurance/getAll',
  async (query: IPagination) => getAll(query)
);
export const getOneThunk = createAsyncThunk(
  'insurance/getOne',
  async (id: string) => getOne(id)
);


const insuranceSlice = createSlice({
  name: 'insurance',
  initialState,
  reducers: {
    setInsurances: (state, action) => {
      state.insurances = action.payload;
    },
    setInsurance: (state, action) => {
      state.insurance = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    clearState: (state) => {
      state.insurances = [];
      state.insurance = null;
      state.total = 0;
      state.currentPage = 1;
    },
    clearErrors: (state) => {
      state.errors=null;
    },
    setAction: (state, action) => {
      state.action = action.payload;
    }
  },

  extraReducers: (builder) => {
    /** CREATE * */
    builder.addCase(createThunk.fulfilled, (state) => {
      state.action = 'CREATED';
    });

    builder.addCase(createThunk.rejected, (state, action) => {
        state.errors = action.payload;
    });

    /** UPDATE * */
    builder.addCase(updateThunk.rejected, (state, action) => {
        state.errors = action.payload;
      });

    /** GET_PROFILE_INFO * */
    builder.addCase(getAllThunk.fulfilled, (state, action) => {
      state.insurances = action.payload.data;
      state.total = action.payload.total;
    });

    /** GET_ONE * */
    builder.addCase(getOneThunk.fulfilled, (state, action) => {
      state.insurance = action.payload;
    });

  }
});

export const getInsurances = (state: RootState) => state.insurance.insurances;
export const getInsurance = (state: RootState) => state.insurance.insurance;
export const getTotal = (state: RootState) => state.insurance.total;
export const getAction = (state: RootState) => state.insurance.action;
export const getCurrentPage = (state: RootState) => state.insurance.currentPage;
export const getErrors = (state: RootState) => state.insurance.errors?.details;

export const {
  setAction,
  setCurrentPage,
  setInsurance,
  clearState,
  clearErrors
} = insuranceSlice.actions;

export default insuranceSlice.reducer;
