import { IPagination } from '../interfaces/index';
import { sendRequest } from '../api/axios';

export interface ICreateBox {
  image: string
  title: string
  description: string
}
interface IUpdateBox  extends  ICreateBox {}

/**
 * @param data
 */

export const create = async (data: ICreateBox) => {
  return sendRequest({
    method: 'POST',
    url: '/boxes',
    data,
  });
};

export const update = async (id: string, data: IUpdateBox) => {
  return sendRequest({
    method: 'PUT',
    url: `/boxes/${id}`,
    data,
  });
};


export const destroy = async (id: string) => {
  return sendRequest({
      method: 'DELETE',
      url: `/boxes/${id}`,
  });
};

export const getAll = async (query: IPagination) => {
  return sendRequest({
    method: 'GET',
    params: query,
    url: '/boxes',
  });
};

export const getOne = async (id: string) => {
  return sendRequest({
    method: 'GET',
    url: `/boxes/${id}/info`,
  });
};

