import { paginationLimit } from '../../constants/index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../index';
import {
  getAll,
  getUnread,
  setRead
} from '../../services/notification.service';
import { IPagination } from '../../interfaces/index';
import { NotificationModel } from '../../models/notification.model';
import { number } from 'prop-types';

interface INotificationProps {
  data: NotificationModel[];
  total: number;
}
interface IParams {
  type?: string;
  isRead?: string;
}
export interface NotificationState {
  notifications: INotificationProps;
  unread: INotificationProps;
  currentPage: number;
  total: number;
  params: IParams;
}

const initialState: NotificationState = {
  notifications: null,
  unread: null,
  currentPage: 1,
  total: 0,
  params: {
    type: 'ALL',
    isRead: 'ALL'
  }
};

export const getAllThunk = createAsyncThunk(
  'notification/getAll',
  async (query: IPagination) => getAll(query)
);

export const getUnreadThunk = createAsyncThunk(
  'notification/getUnread',
  async () => getUnread()
);
export const setReadThunk = createAsyncThunk(
  'notification/setRead',
  async (ids: string[], { dispatch, getState }) => {
    await setRead({ ids });
    // @ts-ignore
    const { notification } = getState();
    dispatch(
      getAllThunk({
        limit: paginationLimit,
        offset: (notification.currentPage - 1) * paginationLimit
      })
    );
  }
);

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    clearState: (state) => {
      state.notifications = null;
      state.currentPage = 1;
      state.params = {
        type: 'ALL',
        isRead: 'ALL'
      };
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setParams: (state, action) => {
      state.params = action.payload;
    }
  },
  extraReducers: (builder) => {
    /** GET SINGLE NOTIFICATION * */
    builder.addCase(getUnreadThunk.fulfilled, (state, action) => {
      state.unread = action.payload;
    });

    /** GET ALL NOTIFICATIONS * */
    builder.addCase(getAllThunk.fulfilled, (state, action) => {
      state.notifications = action.payload;
      state.total = action.payload.total;
    });
  }
});

export const getNotifications = (state: RootState) =>
  state.notification.notifications;
export const getUnreadNotifications = (state: RootState) =>
  state.notification.unread;
export const getCurrentPage = (state: RootState) =>
  state.notification.currentPage;
export const getTotal = (state: RootState) => state.notification.total;
export const getParams = (state: RootState) => state.notification.params;

export const { clearState, setCurrentPage, setParams } =
  notificationSlice.actions;

export default notificationSlice.reducer;
