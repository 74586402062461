import { ISearch } from '../interfaces/index';
import { sendRequest } from '../api/axios';
import {IPagination} from "../interfaces";

export interface ICreateRoom {
    title: string;
    isActive?: boolean;
}

interface  IAddInventory{
    id: string,
    count: number
}

export interface IAddInventories {
    id: string;
    inventories: IAddInventory[];
}

export interface IUpdateRoom extends  ICreateRoom {}

/**
 * @param data
 */
export const create = async (data: ICreateRoom) => {
    return sendRequest({
        method: 'POST',
        url: '/rooms',
        data,
    });
};

export const update = async (id: string, data: IUpdateRoom) => {
    return sendRequest({
        method: 'PUT',
        url: `/rooms/${id}`,
        data
    });
};

export const addInventories = async (data: IAddInventories) => {
    return sendRequest({
        method: 'POST',
        url: `/rooms/${data.id}/inventories`,
        data : {
            inventories: data.inventories
        }
    });
};

export const destroy = async (id: string) => {
    return sendRequest({
        method: 'DELETE',
        url: `/rooms/${id}`,
    });
};

export const getAll = async (query: IPagination) => {
    return sendRequest({
        method: 'GET',
        params: query,
        url: '/rooms',
    });
};

export const getOne = async (id: string) => {
    return sendRequest({
        method: 'GET',
        url: `/rooms/${id}/info`,
    });
};

export const search = async (key: ISearch) => {
    return sendRequest({
        method: 'GET',
        params: key,
        url: `/rooms/search`,
    });
};