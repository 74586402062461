import { sendRequest } from '../api/axios';
import { IPagination } from '../interfaces';

/**
 * @param query
 */


 export const getAll = async (query: IPagination) => {
  return sendRequest({
      method: 'GET',
      params: query,
      url: '/notifications',
  });
};

export const getUnread = async () => {
  return sendRequest({
      method: 'GET',
      url: `/notifications/unread`,
  });
};

export const setRead = async (data) => {
  return sendRequest({
      method: 'POST',
      url: `/notifications/read`,
      data,
  });
};

