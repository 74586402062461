import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import {
  login,
  logout,
  getProfileInfo,
  updateProfileInfo,
  updateProfilePassword,
  IUpdateProfileInfo,
  IUpdateProfilePassword
} from '../../services/auth.service';
import { removeToken, setToken } from '../../helpers/token';
import { constants } from '../../constants';
import { RootState } from '../index';
import { AuthModel } from '../../models/auth.model';

export enum IsAuthenticated {
  AUTH = 'Auth',
  PENDING = 'PENDING',
  GUEST = 'GUEST'
}

export interface AuthState {
  isAuthenticated: IsAuthenticated;
  user: AuthModel;
  pending: boolean;
  errors: any;
  resetData: boolean;
}

const initialState: AuthState = {
  isAuthenticated: IsAuthenticated.PENDING,
  user: null,
  pending: false,
  errors: null,
  resetData: false,
};

export const loginThunk = createAsyncThunk(
  'auth/login',
  async ({ email, password }: { email: string; password: string }, { rejectWithValue }) => {
    try {
      return await login({ email, password });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const logoutThunk = createAsyncThunk('auth/logout', async () => logout());

export const getProfileInfoThunk = createAsyncThunk('auth/profile', async () => getProfileInfo());

export const updateProfileInfoThunk = createAsyncThunk(
  'auth/profile/update',
  async (data: IUpdateProfileInfo, { rejectWithValue, dispatch }) => {
    try {
      await updateProfileInfo(data);
      dispatch(
        getProfileInfoThunk()
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateProfilePasswordThunk = createAsyncThunk(
  'auth/password/update',
  async (data: IUpdateProfilePassword, { rejectWithValue }) => {
    try {
      return await updateProfilePassword(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
      state.pending = false;
    },
    setUser: (state, action: PayloadAction<AuthModel>) => {
      state.user = action.payload;
    },
    resetStore: (state) => {
      state.isAuthenticated = IsAuthenticated.PENDING;
    },
    setPending: (state, action) => {
      state.pending = action.payload;
    },
    setResetData: (state) => {
      state.resetData = false;
    },
    clearForm: (state) => {
      state.errors = [];
      state.resetData = false;
    },
    simpleLogout: (state) => {
      removeToken(constants.ACCESS_TOKEN_KEY);
      removeToken(constants.REFRESH_TOKEN_KEY);
      state.isAuthenticated = IsAuthenticated.GUEST;
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    /** LOGIN * */
    builder.addCase(loginThunk.fulfilled, (state, { payload }) => {
      setToken(payload.accessToken);
      setToken(payload.refreshToken, constants.REFRESH_TOKEN_KEY);
      state.isAuthenticated = IsAuthenticated.PENDING;
    });
    builder.addCase(loginThunk.rejected, (state, { payload }) => {
      state.errors = payload;
    });

    /** LOGOUT * */
    builder.addCase(logoutThunk.fulfilled, (state) => {
      removeToken(constants.ACCESS_TOKEN_KEY);
      removeToken(constants.REFRESH_TOKEN_KEY);
      state.isAuthenticated = IsAuthenticated.GUEST;
      state.user = null;
    });

    /** GET_PROFILE_INFO * */
    builder.addCase(getProfileInfoThunk.fulfilled, (state, action) => {
      state.user = action.payload;
    });

    builder.addCase(getProfileInfoThunk.rejected, (state, action) => {
      state.isAuthenticated = IsAuthenticated.GUEST;
    });

    builder.addCase(updateProfileInfoThunk.rejected, (state, action) => {
      state.errors = action.payload;
    });
    /** UPDATE_PASSWORD * */
    builder.addCase(updateProfilePasswordThunk.fulfilled, (state, action) => {
      state.resetData = true;
    });
    builder.addCase(updateProfilePasswordThunk.rejected, (state, action) => {
      state.errors = action.payload;
    });
  }
});

export const isAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const getUser = (state: RootState) => state.auth.user;
export const getPending = (state: RootState) => state.auth.pending;
export const getResetData = (state: RootState) => state.auth.resetData;
export const getErrors = (state: RootState) => state.auth.errors?.details;
export const { setAuthenticated, resetStore, setPending, clearForm, setResetData, simpleLogout } = authSlice.actions;



export default authSlice.reducer;
