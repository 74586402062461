import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import authReducer from './slices/auth';
import appReducer from './slices/app';
import houseReducer from './slices/house';
import inventoryReducer from './slices/inventory';
import roomReducer from './slices/room';
import boxReducer from './slices/box';
import insuranceReducer from './slices/insurance';
import moveReducer from './slices/move';
import settingReducer from './slices/setting';
import userReducer from './slices/user';
import notificationReducer from './slices/notification';
import partnerReducer from './slices/partner';
import referralPartnerReducer from './slices/referralPartner';
import referralLeads from './slices/referralLeads';
import messageReducer from './slices/message';
import transactionReducer from './slices/transaction';
import connectionsReducer from './slices/connections';

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    auth: authReducer,
    app: appReducer,
    house: houseReducer,
    inventory: inventoryReducer,
    box: boxReducer,
    room: roomReducer,
    insurance: insuranceReducer,
    move: moveReducer,
    setting: settingReducer,
    user: userReducer,
    notification: notificationReducer,
    partner: partnerReducer,
    message: messageReducer,
    transaction: transactionReducer,
    referralLeads: referralLeads,
    referralPartner: referralPartnerReducer,
    connections: connectionsReducer,
  },
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
  RootState,
  unknown,
  Action<string>>;
