import { Suspense, lazy } from 'react';

import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import ReferralPartners from './applications/referralPartners';
import ReferralLeads from './applications/referralLeads';

const Loader = (Component) => (props) => {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
};

const Landing = Loader(lazy(() => import('src/applications/landing')));

const Login = Loader(lazy(() => import('src/applications/login')));

const Houses = Loader(lazy(() => import('src/applications/house')));

const HouseCreate = Loader(lazy(() => import('src/applications/house/create')));

const HouseEdit = Loader(lazy(() => import('src/applications/house/edit')));

const Inventory = Loader(lazy(() => import('src/applications/inventory')));

const InventoryCreate = Loader(
  lazy(() => import('src/applications/inventory/create'))
);

const InventoryEdit = Loader(
  lazy(() => import('src/applications/inventory/edit'))
);

const Room = Loader(lazy(() => import('src/applications/room')));

const RoomEdit = Loader(lazy(() => import('src/applications/room/edit')));

const RoomCreate = Loader(lazy(() => import('src/applications/room/create')));

const Box = Loader(lazy(() => import('src/applications/box')));

const BoxEdit = Loader(lazy(() => import('src/applications/box/edit')));

const BoxCreate = Loader(lazy(() => import('src/applications/box/create')));

const Leads = Loader(lazy(() => import('src/applications/leads')));

const LoadBoard = Loader(lazy(() => import('src/applications/load-board')));

const AcceptedMove = Loader(lazy(() => import('src/applications/accepted-moves')));

const ClosedJobs = Loader(lazy(() => import('src/applications/closed-job')));

const MoveInfo = Loader(lazy(() => import('src/applications/leads/info')));

const Insurance = Loader(lazy(() => import('src/applications/insurance')));

const InsuranceEdit = Loader(
  lazy(() => import('src/applications/insurance/edit'))
);

const InsuranceCreate = Loader(
  lazy(() => import('src/applications/insurance/create'))
);

const Status404 = Loader(lazy(() => import('src/applications/status404')));

const User = Loader(lazy(() => import('src/applications/users')));

const UserEdit = Loader(lazy(() => import('src/applications/users/edit')));

const Account = Loader(lazy(() => import('src/applications/account')));

const Settings = Loader(lazy(() => import('src/applications/setting')));

const Notifications = Loader(lazy(() => import('src/applications/notification')));

const Partners = Loader(lazy(() => import('src/applications/partner')));

const PartnerView = Loader(lazy(() => import('src/applications/partner/view')));

const ApprovedPartners = Loader(lazy(() => import('src/applications/approved-partners')));
const ApprovedPartnerView = Loader(lazy(() => import('src/applications/approved-partners/view')));

const Messages = Loader(lazy(() => import('src/applications/message')));
const MessageView = Loader(lazy(() => import('src/applications/message/view')));

const Transaction = Loader(lazy(() => import('src/applications/transaction')));
const Connections = Loader(lazy(() => import('src/applications/connections')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Login />
      }
    ]
  },
  {
    path: 'dashboard',
    element: <SidebarLayout />,
    children: [
      {
        path: 'job-board/active',
        element: <LoadBoard />
      },
      {
        path: 'job-board/passed',
        element: <LoadBoard />
      },
      {
        path: 'quoted-job',
        element: <Leads />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'settings',
        element: <Settings />
      },
      {
        path: 'notifications',
        element: <Notifications />
      },
      {
        path: 'connections',
        element: <Connections />
      },
      {
        path: '',
        element: <Landing />
      },
      {
        path: 'houses',
        children: [
          {
            path: '',
            element: <Houses />
          },
          {
            path: ':id/edit',
            element: <HouseEdit />
          },
          {
            path: 'create',
            element: <HouseCreate />
          }
        ]
      },
      {
        path: 'rooms',
        children: [
          {
            path: '',
            element: <Room />
          },
          {
            path: ':id/edit',
            element: <RoomEdit />
          },
          {
            path: 'create',
            element: <RoomCreate />
          }
        ]
      },
      {
        path: 'inventories',
        children: [
          {
            path: '',
            element: <Inventory />
          },
          {
            path: 'create',
            element: <InventoryCreate />
          },
          {
            path: ':id/edit',
            element: <InventoryEdit />
          }
        ]
      },
      {
        path: 'boxes',
        children: [
          {
            path: '',
            element: <Box />
          },
          {
            path: ':id/edit',
            element: <BoxEdit />
          },
          {
            path: 'create',
            element: <BoxCreate />
          }
        ]
      },
      {
        path: 'insurances',
        children: [
          {
            path: '',
            element: <Insurance />
          },
          {
            path: ':id/edit',
            element: <InsuranceEdit />
          },
          {
            path: 'create',
            element: <InsuranceCreate />
          }
        ]
      },
      {
        path: 'move-info/:id',
        element: <MoveInfo />
      },
      // {
      //   path: 'leads/:state',
      //   children: [
      //     {
      //       path: '',
      //       element: <Leads />
      //     },
      //   ]
      // },
      {
        path: 'accepted-moves/:state',
        children: [
          {
            path: '',
            element: <AcceptedMove />
          },
        ]
      },
      {
        path: 'closed-jobs',
        children: [
          {
            path: '',
            element: <ClosedJobs />
          },
        ]
      },
      {
        path: 'customers',
        children: [
          {
            path: '',
            element: <User />
          },
          {
            path: ':id/edit',
            element: <UserEdit />
          }
        ]
      },
      {
        path: 'referral-partners',
        children: [
          {
            path: '',
            element: <ReferralPartners />
          }
        ]
      },
      {
        path: 'referral-leads',
        children: [
          {
            path: '',
            element: <ReferralLeads />
          }
        ]
      },
      {
        path: 'partners',
        children: [
          {
            path: 'active',
            element: <Partners />,
          },
          {
            path: 'draft',
            element: <Partners />,
          },
          {
            path: ':id/view',
            element: <PartnerView />
          },
        ]
      },
      {
        path: 'approved-partners',
        children: [
          {
            path: '',
            element: <ApprovedPartners />
          },
          {
            path: ':id/view',
            element: <ApprovedPartnerView />
          },
        ]
      },
      {
        path: 'messages',
        children: [
          {
            path: '',
            element: <Messages />
          },
          {
            path: ':id',
            element: <MessageView />
          },
        ]
      },
      {
        path: 'transactions',
        children: [
          {
            path: '',
            element: <Transaction />
          },
        ]
      },
    ]
  },
  {
    path: '*',
    element: <Status404 />
  }
];

export default routes;
