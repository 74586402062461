import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../index';
import { SettingsModel } from '../../models/setting.model';
import { updateSettings, getSettings } from '../../services/setting.service';


export interface SettingState {
  errors: any;
  settings: SettingsModel[]
}

const initialState: SettingState = {
  errors: null,
  settings: null
};


export const getSettingsThunk = createAsyncThunk('setting/get', async () => getSettings());

export const updateSettingsThunk = createAsyncThunk(
  'setting/update',
  async ( data: SettingsModel[] , { rejectWithValue }) => {
    try {
      return await updateSettings({items: data});
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);


const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    clearState: (state) => {
      state.errors = [];
      state.settings = null
    }
  },

  extraReducers: (builder) => {

    /** GET_SETTINGS_INFO * */
    builder.addCase(getSettingsThunk.fulfilled, (state, action) => {
      state.settings = action.payload;
    });

    /** UPDATE_SETTINGS_INFO * */
    builder.addCase(updateSettingsThunk.fulfilled, (state, action) => {
      state.settings = action.payload;
    });

    builder.addCase(updateSettingsThunk.rejected, (state, action) => {
      state.errors = action.payload;
    });


  }
});

export const getSetting = (state: RootState) => state.setting.settings;
export const getErrors = (state: RootState) => state.setting?.errors?.details;
export const {clearState} = settingSlice.actions;



export default settingSlice.reducer;
