import ReactDOM from 'react-dom';
import 'nprogress/nprogress.css';
import 'react-toastify/dist/ReactToastify.css';

import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from 'src/serviceWorker';
import { SidebarProvider } from 'src/contexts/SidebarContext';

import App from './App';
import { store } from './store';

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <SidebarProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SidebarProvider>
    </HelmetProvider>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
