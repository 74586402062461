import { sendRequest } from '../api/axios';
import { IPagination } from '../interfaces/index';

/**
 * @param query
 */


export const getAll = async (query: IPagination) => {
  return sendRequest({
    method: 'GET',
    params: query,
    url: 'admin/users',
  });
};

export const getOne = async (id: string) => {
  return sendRequest({
    method: 'GET',
    url: `/users/${id}/info`,
  });
};

export const getStatistic = async () => {
  return sendRequest({
    method: 'GET',
    url: `/users/statistic`,
  });
};

export const deleteUserRequest = async (id: string) => sendRequest({
  url: `/users/${id}`,
  method: 'DELETE',
});