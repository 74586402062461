import { sendRequest } from '../api/axios';
import { IPagination } from '../interfaces';


export const getAll = async (query: IPagination) => sendRequest({
  method: 'GET',
  params: query,
  url: '/admin/questions'
})

export const getOne = async (id: string) => sendRequest({
  method: 'GET',
  url: `/contactUs/${id}/info`
})