import { FC, ReactNode, useEffect } from 'react';

import { Box, alpha, useTheme } from '@mui/material';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IsAuthenticated, isAuthenticated } from "../../store/slices/auth";
import MuiBackdrop from '../../components/Backdrop';
import { getStatesThunk, getHousesThunk } from 'src/store/slices/app';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const result = useAppSelector(isAuthenticated);

  useEffect(() => {
    dispatch(getStatesThunk())
    dispatch(getHousesThunk())
    // navigate('/dashboard/houses')
    // @ts-ignore
  }, [])

  return (
    result === IsAuthenticated.AUTH ?
      <Box
        sx={{
          flex: 1,
          height: '100%',
          '.MuiPageTitle-wrapper': {
            background: theme.colors.alpha.white[100],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow: `0px 2px 4px ${alpha(theme.colors.alpha.black[100], 0.08)}`
          },
        }}
      >
        <Header />
        <Sidebar />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `${theme.header.height}`,
            [theme.breakpoints.up('lg')]: {
              ml: `${theme.sidebar.width}`,
            },
          }}
        >
          <Box display="block">
            <Outlet />
          </Box>
        </Box>
      </Box> : result === IsAuthenticated.PENDING ? <MuiBackdrop /> : <Navigate to='/' />

  );
};

export default SidebarLayout;
