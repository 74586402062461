export const downloadFileFromBlob = (blob: Blob, name: string) => {
  const fileLink = document.createElement('a');
  fileLink.href = window.URL.createObjectURL(blob);
  fileLink.setAttribute('download', `${name}.csv`);
  fileLink.setAttribute('target', '_blank');
  document.body.appendChild(fileLink);
  fileLink.click();
  fileLink.remove();
};

