import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllTransactionRequest } from '../../services/transaction.service';
import { IPagination } from '../../interfaces';
import { TransactionModel } from '../../models/transaction.model';

export interface TransactionState {
  data: TransactionModel[];
  total: number;
  currentPage: number;
}

const initialState: TransactionState = {
  data: [],
  total: 0,
  currentPage: 1
};

export const getAllTransactions = createAsyncThunk(
  'transactions/getAll',
  async (query: IPagination) => getAllTransactionRequest(query)
);


const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    clearState: (state) => {
      state.data = [];
      state.total = 0;
      state.currentPage = 1;
    }
  },
  extraReducers: (builder) => {
    /** GET ALL * */
    builder.addCase(getAllTransactions.fulfilled, (state, { payload: { data, total } }) => {
      state.data = data;
      state.total = total;
    });
  }
});


export const { setCurrentPage, clearState } = transactionSlice.actions;

export default transactionSlice.reducer;
