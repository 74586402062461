import React, { useState, createContext, useCallback } from 'react';

import { ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';

import { themeCreator } from './base';

export const ThemeContext = createContext({});

const ThemeProviderWrapper: React.FC = ({ children }) => {
  const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = useCallback((themeNameValue: string): void => {
    localStorage.setItem('appTheme', themeNameValue);
    _setThemeName(themeNameValue);
  }, []);

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
