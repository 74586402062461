import { constants } from '../constants';

export const getToken = (key = constants.ACCESS_TOKEN_KEY) => {
  return localStorage.getItem(key);
};

export const removeToken = (key = constants.ACCESS_TOKEN_KEY) => {
  localStorage.removeItem(key);
};

export const setToken = (value: string, key = constants.ACCESS_TOKEN_KEY) => {
  localStorage.setItem(key, value);
};
