import { useEffect } from 'react';

import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import { ToastContainer } from 'react-toastify';

import ThemeProvider from './theme/ThemeProvider';
import { init } from './api/axios';
import { IsAuthenticated, isAuthenticated, setAuthenticated, getProfileInfoThunk } from './store/slices/auth';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { getToken } from './helpers/token';
import { getUnreadThunk } from './store/slices/notification';

function App() {
  init();
  const content = useRoutes(router);
  const dispatch = useAppDispatch();
  const result = useAppSelector(isAuthenticated);

  useEffect(() => {
    const token = getToken();
    // if(token && result === IsAuthenticated.AUTH) dispatch(getUnreadThunk());
    if (token && result === IsAuthenticated.PENDING) setTimeout(() => dispatch(setAuthenticated(IsAuthenticated.AUTH)), 1000);
    if (!token && result === IsAuthenticated.PENDING) dispatch(setAuthenticated(IsAuthenticated.GUEST));
  });

  useEffect(() => {
    if (result === IsAuthenticated.AUTH) {
      dispatch(getProfileInfoThunk());
    }
  }, [result])


  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <ToastContainer />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
