import MuiBackdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


export default function Backdrop() {

    return (
        <MuiBackdrop
            sx={{ color: '#fff', background: '#F8CF61', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <CircularProgress color="inherit" />
        </MuiBackdrop>
    );
}