import { paginationLimit } from '../../constants/index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getOne,
  getAll,
  create,
  update,
  destroy,
  ICreateBox
} from '../../services/boxes.service';

import { RootState } from '../index';
import { IPagination } from '../../interfaces';
import { BoxModel } from '../../models/box.model';

interface IUpdateBox {
  id: string;
  data: ICreateBox;
}
interface ISelectedBox extends BoxModel{
  count?: number;
}

export interface BoxState {
  box: BoxModel;
  boxes: BoxModel[];
  total: number;
  currentPage: number;
  action: 'CREATED' | 'EDIT' | 'INDEX';
  errors: any;
  selectedPerson: [] | ISelectedBox[];
  selectedKitchen: [] | ISelectedBox[];
}

const initialState: BoxState = {
  box: null,
  boxes: [],
  total: 0,
  currentPage: 1,
  action: 'INDEX',
  errors: null,
  selectedPerson: [],
  selectedKitchen: [],
};

export const createThunk = createAsyncThunk(
  'box/create',
  async (data: ICreateBox, { rejectWithValue }) =>{
    try {
      return await create(data);
    } catch (err) {
      return rejectWithValue(err);
    }

  } 
);

export const updateThunk = createAsyncThunk(
  'box/update',
  async (data: IUpdateBox, { rejectWithValue }) =>{
    try {
      return await update(data.id, data.data);
    } catch (err) {
      return rejectWithValue(err);
    }

  }
);

export const destroyThunk = createAsyncThunk(
  'box/destroy',
  async (id: string, thunkAPI) => {
    // @ts-ignore
    const { box } = thunkAPI.getState();
    await destroy(id);
    thunkAPI.dispatch(
      getAllThunk({
        limit: paginationLimit,
        offset: (box.currentPage - 1) * paginationLimit
      })
    );
  }
);

export const getOneThunk = createAsyncThunk('box/getOne', async (id: string) =>
  getOne(id)
);

export const getAllThunk = createAsyncThunk(
  'box/getAll',
  async (query: IPagination) => getAll(query)
);

const boxSlice = createSlice({
  name: 'box',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    clearState: (state) => {
      state.boxes = [];
      state.box = null;
      state.total = 0;
      state.currentPage = 1;
      state.errors=null;
      state.selectedPerson=[]
      state.selectedKitchen=[]
    },
    setAction: (state, action) => {
      state.action = action.payload;
    },
    setSelectedPerPerson: (state, action) => {
      state.selectedPerson = action.payload;
    },
    setSelectedKitchen: (state, action) => {
      state.selectedKitchen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createThunk.fulfilled, (state, { payload }) => {
      state.action = 'CREATED';
    });

    builder.addCase(createThunk.rejected, (state, action) => {
      state.errors = action.payload;
    });
    /** UPDATE BOX * */
    builder.addCase(updateThunk.fulfilled, (state, action) => {});
    builder.addCase(updateThunk.rejected, (state, action) => {
      state.errors = action.payload;
    });
    /** GET SINGLE BOX * */
    builder.addCase(getOneThunk.fulfilled, (state, action) => {
      state.box = action.payload;
    });

    /** GET ALL BOXES * */
    builder.addCase(getAllThunk.fulfilled, (state, action) => {
      state.boxes = action.payload.data;
      state.total = action.payload.total;
    });
  }
});

export const getBox = (state: RootState) => state.box.box;
export const getBoxes = (state: RootState) => state.box.boxes;
export const getAction = (state: RootState) => state.box.action;
export const getTotal = (state: RootState) => state.box.total;
export const getErrors = (state: RootState) => state.box.errors?.details;
export const getCurrentPage = (state: RootState) => state.box.currentPage;
export const getSelectedPerPerson = (state: RootState) => state.box.selectedPerson;
export const getSelectedKitchen = (state: RootState) => state.box.selectedKitchen;

export const { setCurrentPage, clearState, setAction, setSelectedPerPerson, setSelectedKitchen } =  boxSlice.actions;

export default boxSlice.reducer;
