import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  create,
  ICreateRoom,
  update,
  IUpdateRoom,
  getAll,
  getOne,
  destroy,
  search
} from '../../services/room.service';
import { RootState } from '../index';
import { RoomModel } from '../../models/room.model';
import { IPagination } from '../../interfaces';
import { paginationLimit } from '../../constants';

interface IUpdateThunk {
  id: string;
  data: IUpdateRoom;
}
interface ISelectedRoom extends RoomModel {
  count?: number;
}
export interface RoomState {
  rooms: [] | RoomModel[];
  room: null | RoomModel;
  total: number;
  currentPage: number;
  action: 'CREATED' | 'EDIT' | 'INDEX';
  searchResult: [] | RoomModel[];
  selected: [] | ISelectedRoom[];
  errors: any;
}

const initialState: RoomState = {
  rooms: [],
  room: null,
  total: 0,
  currentPage: 1,
  action: 'INDEX',
  selected: [],
  searchResult: [],
  errors: null
};

export const createThunk = createAsyncThunk(
  'room/create',
  async (data: ICreateRoom, { rejectWithValue }) => {
    try {
      return await create(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateThunk = createAsyncThunk(
  'room/update',
  async (data: IUpdateThunk, { rejectWithValue }) => {
    try {
      return await update(data.id, data.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const destroyThunk = createAsyncThunk(
  'room/destroy',
  async (id: string, thunkAPI) => {
    // @ts-ignore
    const { room } = thunkAPI.getState();
    await destroy(id);
    thunkAPI.dispatch(
      getAllThunk({
        limit: paginationLimit,
        offset: (room.currentPage - 1) * paginationLimit
      })
    );
  }
);

export const getAllThunk = createAsyncThunk(
  'room/getAll',
  async (query: IPagination) => getAll(query)
);
export const getOneThunk = createAsyncThunk('room/getOne', async (id: string) =>
  getOne(id)
);
export const searchThunk = createAsyncThunk('room/search', async (data: any) =>
  search(data)
);

const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setRooms: (state, action) => {
      state.rooms = action.payload;
    },
    setRoom: (state, action) => {
      state.room = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    clearState: (state) => {
      state.rooms = [];
      state.room = null;
      state.total = 0;
      state.currentPage = 1;
      state.searchResult = [];
      state.selected = [];
      state.errors = null;
    },

    setSearchResult: (state, action) => {
      state.searchResult = action.payload;
    },

    setSelected: (state, action) => {
      state.selected = action.payload;
    },

    setAction: (state, action) => {
      state.action = action.payload;
    },
    clearErrors: (state) => {
      state.errors = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createThunk.fulfilled, (state, { payload }) => {
      state.action = 'CREATED';
    });
    builder.addCase(createThunk.rejected, (state, action) => {
      state.errors = action.payload;
    });
    /** UPDATE * */
    builder.addCase(updateThunk.fulfilled, (state) => {});
    builder.addCase(updateThunk.rejected, (state, action) => {
      state.errors = action.payload;
    });
    /** DESTROY * */
    builder.addCase(destroyThunk.fulfilled, (state) => {});

    /** GET_PROFILE_INFO * */
    builder.addCase(getAllThunk.fulfilled, (state, action) => {
      state.rooms = action.payload.data;
      state.total = action.payload.total;
    });

    /** GET_ONE * */
    builder.addCase(getOneThunk.fulfilled, (state, action) => {
      state.room = action.payload;
    });

    /** SEARCH * */
    builder.addCase(searchThunk.fulfilled, (state, action) => {
      state.searchResult = action.payload;
    });
  }
});

export const getRooms = (state: RootState) => state.room.rooms;
export const getRoom = (state: RootState) => state.room.room;
export const getSearchResult = (state: RootState) => state.room.searchResult;
export const getSelected = (state: RootState) => state.room.selected;
export const getAction = (state: RootState) => state.room.action;
export const getTotal = (state: RootState) => state.room.total;
export const getCurrentPage = (state: RootState) => state.room.currentPage;
export const getErrors = (state: RootState) => state.room.errors?.details;
export const {
  setRoom,
  setCurrentPage,
  setAction,
  clearState,
  setSearchResult,
  setSelected,
  clearErrors
} = roomSlice.actions;

export default roomSlice.reducer;
