import { paginationLimit } from '../../constants/index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getOne,
  getAll,
  create,
  update,
  destroy,
  ICreateHouse
} from '../../services/houses.service';
import { HouseModel } from '../../models/house.model';

import { RootState } from '../index';
import { IPagination } from '../../interfaces';

interface IUpdateHouse {
  id: string;
  data: ICreateHouse;
}
export interface HouseState {
  house: HouseModel;
  houses: HouseModel[];
  total: number;
  currentPage: number;
  action: 'CREATED' | 'EDIT' | 'INDEX';
  errors: any;
}

const initialState: HouseState = {
  house: null,
  houses: [],
  total: 0,
  currentPage: 1,
  action: 'INDEX',
  errors: null
};

export const createThunk = createAsyncThunk(
  'house/create',
  async (data: ICreateHouse, { rejectWithValue }) => {
    try {
      return await create(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateThunk = createAsyncThunk(
  'house/update',
  async (data: IUpdateHouse, { rejectWithValue }) => {
    try {
      return await update(data.id, data.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const destroyThunk = createAsyncThunk(
  'house/destroy',
  async (id: string, thunkAPI) => {
    // @ts-ignore
    const { house } = thunkAPI.getState();
    await destroy(id);
    thunkAPI.dispatch(
      getAllThunk({
        limit: paginationLimit,
        offset: (house.currentPage - 1) * paginationLimit
      })
    );
  }
);

export const getOneThunk = createAsyncThunk(
  'house/getOne',
  async (id: string) => getOne(id)
);

export const getAllThunk = createAsyncThunk(
  'house/getAll',
  async (query: IPagination) => getAll(query)
);

const houseSlice = createSlice({
  name: 'house',
  initialState,
  reducers: {
    setHouses: (state, action) => {
      state.houses = action.payload;
    },
    setSelectedHouse: (state, action) => {
      state.house = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    clearState: (state) => {
      state.houses = [];
      state.house = null;
      state.total = 0;
      state.currentPage = 1;
    },
    setAction: (state, action) => {
      state.action = action.payload;
    },
    clearErrors: (state) => {
      state.errors = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createThunk.fulfilled, (state, { payload }) => {
      state.action = 'CREATED';
    });
    builder.addCase(createThunk.rejected, (state, action) => {
      state.errors = action.payload;
    });
    /** UPDATE HOUSE * */
    builder.addCase(updateThunk.fulfilled, (state, action) => { });
    builder.addCase(updateThunk.rejected, (state, action) => {
      state.errors = action.payload;
    });
    /** GET SINGLE HOUSE * */
    builder.addCase(getOneThunk.fulfilled, (state, action) => {
      state.house = action.payload;
    });

    /** GET ALL HOUSES * */
    builder.addCase(getAllThunk.fulfilled, (state, action) => {
      state.houses = action.payload;
      // state.houses = action.payload.data;
      // state.total = action.payload.total;
    });
  }
});

export const getSelectedHouse = (state: RootState) => state.house.house;
export const getHouses = (state: RootState) => state.house.houses;
export const getAction = (state: RootState) => state.house.action;
export const getTotal = (state: RootState) => state.house.total;
export const getErrors = (state: RootState) => state.house.errors?.details;
export const getCurrentPage = (state: RootState) => state.house.currentPage;

export const { setCurrentPage, clearState, setAction, clearErrors } = houseSlice.actions;

export default houseSlice.reducer;
