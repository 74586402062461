import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { Box, Button, FormControl, InputLabel, MenuItem, Stack, Select, Input } from "@mui/material";
import { useAppSelector } from "src/store/hooks";
import { getStatesSelector, getHousesSelector } from "src/store/slices/app";
import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { FilterTypes, IFiltersFields } from './types';
import { StyledInput } from './styled';

interface FiltersComponentProps {
  fields: IFiltersFields[];
  filters: FilterTypes
  defaultProps?: string
  setFilters: React.Dispatch<React.SetStateAction<FilterTypes>>
  handleDownload?: () => Promise<void>
  handleFiltersChange: (key: string, value: string) => void
}

const selectFilter = (item, filters, handleFiltersChange) => {
  const states = useAppSelector(getStatesSelector)
  const houses = useAppSelector(getHousesSelector)

  const renderOptionsHtml = (name) => {
    switch (name) {
      case 'houseId':
        return (
          houses.map(({ id, title }) => (
            <MenuItem key={id} value={id}>
              {title}
            </MenuItem>
          ))
        )
      case 'toState':
      case 'fromState':
        return (
          states.map(({ id, code, name }) => (
            <MenuItem key={id + code} value={code}>
              {name}
            </MenuItem>
          ))
        )
      default:
        return (
          item.options.map(({ label, value }) => (
            <MenuItem key={label} value={value}>
              {label}
            </MenuItem>
          ))
        )
    }
  }

  return (
    <Box
      key={item.name}
      sx={{ minWidth: 120 }}
    >
      <FormControl fullWidth variant="outlined">
        <InputLabel>{item.title}</InputLabel>
        <Select
          autoWidth
          label={item.title}
          value={filters[item.name] || ''}
          onChange={(e) => handleFiltersChange(item.name, e.target.value)}
        >
          {renderOptionsHtml(item.name)}
        </Select>
      </FormControl>
    </Box>
  )
}

const numberFilter = (item, filters, handleFiltersChange) => {
  return (
    <Box
      key={item.name}
      sx={{ minWidth: 120 }}
    >
      <FormControl fullWidth variant="outlined">
        <StyledInput
          type={item.type}
          value={filters[item.name] || ''}
          placeholder={item.placeholder}
          onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
          onChange={(e) => {
            handleFiltersChange(item.name, e.target.value)
          }}
        />
      </FormControl>
    </Box>
  )
}

const textFilter = (item, filters, handleFiltersChange) => {
  return (
    <Box
      key={item.name}
      sx={{ minWidth: 120 }}
    >
      <FormControl fullWidth variant="outlined">
        <StyledInput
          type={item.type}
          value={filters[item.name] || ''}
          placeholder={item.placeholder}
          onChange={(e) => handleFiltersChange(item.name, e.target.value)}
        />
      </FormControl>
    </Box>
  )
}

const dateFilter = (item, filters, handleFiltersChange) => {
  const value = filters[item.name] ? dayjs(filters[item.name]) : null

  return (
    <LocalizationProvider
      key={item.name}
      dateAdapter={AdapterDayjs}
    >
      <DesktopDatePicker
        label={`${item.title}`}
        inputFormat="MM.DD.YYYY"
        value={value}
        onChange={(newValue: Dayjs | null) => {
          const iso = newValue.toISOString()
          handleFiltersChange(item.name, iso)
        }}
        renderInput={(params) =>
          <TextField
            {...params}
          />}
      />
    </LocalizationProvider>
  )
}

const customCases = {
  'select': (item, filters, handleFiltersChange) => selectFilter(item, filters, handleFiltersChange),
  'text': (item, filters, handleFiltersChange) => textFilter(item, filters, handleFiltersChange),
  'number': (item, filters, handleFiltersChange) => numberFilter(item, filters, handleFiltersChange),
  'date': (item, filters, handleFiltersChange) => dateFilter(item, filters, handleFiltersChange),
  'default': null
};

const renderFilters = (item, filters, handleFiltersChange) => {
  const element = customCases[item.type] || customCases['default']

  return element(item, filters, handleFiltersChange)
}

const Filters = ({ defaultProps, fields, filters, setFilters, handleDownload, handleFiltersChange }: FiltersComponentProps) => {
  return (
    <Stack
      padding={2}
      columnGap={2}
      flexDirection="row"
      sx={{ 'overflowX': 'scroll' }}
    >
      {
        handleDownload && (
          <Box
            display='flex'
            justifyContent='end'
          >
            <Button
              variant='contained'
              onClick={handleDownload}
            >
              Download
            </Button>
          </Box>
        )
      }

      {fields.map((item) => renderFilters(item, filters, handleFiltersChange))}

      <Button
        onClick={() => {
          if (defaultProps) {
            setFilters({ status: defaultProps })
            return
          }

          setFilters({ page: '1' })
        }}
      >
        Clear filters
      </Button>
    </Stack >
  );
}

export default Filters;