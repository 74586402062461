import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


import { RootState } from '../index';
import { IPagination } from '../../interfaces';
import { UserModel } from '../../models/user.model';
import { deleteUserRequest, getAll, getOne, getStatistic } from '../../services/user.service';
import { GetAllUsersQuery } from '../../interfaces/users';

interface IStatistic {
  total: number,
  today: number
}

export interface UserState {
  user: UserModel;
  users: UserModel[];
  total: number;
  currentPage: number;
  statistic: IStatistic;
  action: 'CREATED' | 'EDIT' | 'INDEX';
  errors: any;
}

const initialState: UserState = {
  user: null,
  users: [],
  total: 0,
  statistic: null,
  currentPage: 1,
  action: 'INDEX',
  errors: null,
};



export const getOneThunk = createAsyncThunk('user/getOne', async (id: string) =>
  getOne(id)
);

export const getAllThunk = createAsyncThunk(
  'user/getAll',
  async (query: GetAllUsersQuery) => getAll(query)
);

export const getStatisticThunk = createAsyncThunk(
  'user/getStatistic',
  async () => getStatistic()
);

export const deleteUserThunk = createAsyncThunk(
  'user/delete',
  async (id: string) => deleteUserRequest(id)
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    clearState: (state) => {
      state.users = [];
      state.user = null;
      state.total = 0;
      state.currentPage = 1;
      state.errors=null;
    },
    setAction: (state, action) => {
      state.action = action.payload;
    },

  },
  extraReducers: (builder) => {

    /** GET SINGLE USER * */
    builder.addCase(getOneThunk.fulfilled, (state, action) => {
      state.user = action.payload;
    });

    /** GET ALL USERS * */
    builder.addCase(getAllThunk.fulfilled, (state, action) => {
      state.users = action.payload.data;
      state.total = action.payload.total;
    });

    /** GET ALL STATISTIC * */
    builder.addCase(getStatisticThunk.fulfilled, (state, action) => {
      state.statistic = action.payload;
    });
  }
});

export const getUser = (state: RootState) => state.user.user;
export const getUsers = (state: RootState) => state.user.users;
export const getAction = (state: RootState) => state.user.action;
export const getStatisticAction = (state: RootState) => state.user.statistic;
export const getTotal = (state: RootState) => state.user.total;
export const getErrors = (state: RootState) => state.user.errors?.details;
export const getCurrentPage = (state: RootState) => state.user.currentPage;

export const { setCurrentPage, clearState, setAction } =  userSlice.actions;

export default userSlice.reducer;
