import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  create,
  ICreateInventory,
  update,
  IUpdateInventory,
  getAll,
  getOne,
  destroy,
  search
} from '../../services/inventory.service';
import { RootState } from '../index';
import { InventoryModel } from '../../models/inventory.model';

import { IPagination } from '../../interfaces';
import { paginationLimit } from '../../constants';

interface IUpdateThunk {
  id: string;
  data: IUpdateInventory;
}

interface ISelectedInventory extends InventoryModel {
  count?: number;
}

export interface InventoryState {
  inventories: [] | InventoryModel[];
  searchResult: [] | InventoryModel[];
  selected: [] | ISelectedInventory[];
  inventory: null | InventoryModel;
  action: 'CREATED' | 'EDIT' | 'INDEX';
  total: number;
  currentPage: number;
  errors: any;
}

const initialState: InventoryState = {
  inventories: [],
  selected: [],
  inventory: null,
  searchResult: [],
  total: 0,
  currentPage: 1,
  action: 'INDEX',
  errors: null
};

export const createThunk = createAsyncThunk(
  'inventory/create',
  async (data: ICreateInventory, { rejectWithValue }) => {
    try {
      return await create(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateThunk = createAsyncThunk(
  'inventory/update',
  async (data: IUpdateThunk, { rejectWithValue }) => {
    try {
      return await update(data.id, data.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const destroyThunk = createAsyncThunk(
  'inventory/destroy',
  async (id: string, thunkAPI) => {
    // @ts-ignore
    const { inventory } = thunkAPI.getState();
    await destroy(id);
    thunkAPI.dispatch(
      getAllThunk({
        limit: paginationLimit,
        offset: (inventory.currentPage - 1) * paginationLimit
      })
    );
  }
);

export const getAllThunk = createAsyncThunk(
  'inventory/getAll',
  async (query: IPagination) => getAll(query)
);
export const getOneThunk = createAsyncThunk(
  'inventory/getOne',
  async (id: string) => getOne(id)
);
export const searchThunk = createAsyncThunk(
  'inventory/search',
  async (data: any) => search(data)
);

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setInventories: (state, action) => {
      state.inventories = action.payload;
    },
    setInventory: (state, action) => {
      state.inventory = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    clearState: (state) => {
      state.inventories = [];
      state.searchResult = [];
      state.selected = [];
      state.inventory = null;
      state.total = 0;
      state.currentPage = 1;
    },
    clearErrors: (state) => {
      state.errors=null;
    },
    setSearchResult: (state, action) => {
      state.searchResult = action.payload;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    setAction: (state, action) => {
      state.action = action.payload;
    }
  },
  extraReducers: (builder) => {
    /** CREATE * */
    builder.addCase(createThunk.fulfilled, (state, { payload }) => {
      state.action = 'CREATED';
    });

    builder.addCase(createThunk.rejected, (state, action) => {
        state.errors = action.payload;
    });

    /** UPDATE * */
    builder.addCase(updateThunk.fulfilled, (state) => {});
    builder.addCase(updateThunk.rejected, (state, action) => {
        state.errors = action.payload;
      });
    /** DESTROY * */
    builder.addCase(destroyThunk.fulfilled, (state) => {});

    /** GET_PROFILE_INFO * */
    builder.addCase(getAllThunk.fulfilled, (state, action) => {
      state.inventories = action.payload.data;
      state.total = action.payload.total;
    });

    /** GET_ONE * */
    builder.addCase(getOneThunk.fulfilled, (state, action) => {
      state.inventory = action.payload;
    });

    /** SEARCH * */
    builder.addCase(searchThunk.fulfilled, (state, action) => {
      state.searchResult = action.payload;
    });
  }
});

export const getInventories = (state: RootState) => state.inventory.inventories;
export const getInventory = (state: RootState) => state.inventory.inventory;
export const getSearchResult = (state: RootState) => state.inventory.searchResult;
export const getSelected = (state: RootState) => state.inventory.selected;
export const getTotal = (state: RootState) => state.inventory.total;
export const getAction = (state: RootState) => state.inventory.action;
export const getCurrentPage = (state: RootState) => state.inventory.currentPage;
export const getErrors = (state: RootState) => state.inventory.errors?.details;

export const {
  setAction,
  setCurrentPage,
  setInventory,
  clearState,
  setSearchResult,
  setSelected,
  clearErrors
} = inventorySlice.actions;

export default inventorySlice.reducer;
