import { useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from '../../components/PageTitleWrapper';
import { Container, Grid, Typography, Card } from '@mui/material';
import Footer from '../../components/Footer';
import List from './components/List';
import { useAppDispatch } from '../../store/hooks';


import { clearState, setCurrentPage } from 'src/store/slices/referralLeads';

const ReferralLeads = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentPage(1));

    return () => {
      dispatch(clearState());
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Referral Portal Leads</title>
      </Helmet>
      <PageTitleWrapper>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              Referral Portal Leads
            </Typography>
          </Grid>
        </Grid>
      </PageTitleWrapper>
      <Container
        sx={{
          maxWidth: '1920px !important',
          width: '100%'
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <List />
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default ReferralLeads;
