import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../index';
import { getAllReferralLeads } from 'src/services/referralLeads.service';
import { ReferralLeadsModel } from 'src/models/referralLeads.model';
import { IPagination } from 'src/interfaces';


export interface UserState {
  leads: ReferralLeadsModel[];
  total: number;
  currentPage: number;
  errors: any;
}

const initialState: UserState = {
  leads: [],
  total: 0,
  currentPage: 1,
  errors: null,
};


export const getAllReferralLeadsThunk = createAsyncThunk(
  'user/getAll',
  async (query: IPagination) => getAllReferralLeads(query)
);

const referralLeadsSlice = createSlice({
  name: 'referralLeads',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    clearState: (state) => {
      state.leads = [];
      state.total = 0;
      state.currentPage = 1;
      state.errors = null;
    },

  },
  extraReducers: (builder) => {

    /** GET ALL leads * */
    builder.addCase(getAllReferralLeadsThunk.fulfilled, (state, action) => {
      state.leads = action.payload.data;
      state.total = action.payload.total;
    });
    builder.addCase(getAllReferralLeadsThunk.rejected, (state, action) => {
      state.errors = action.payload;
    });

  }
});

export const getReferralLeads = (state: RootState) => state.referralLeads.leads;
export const getTotal = (state: RootState) => state.referralLeads.total;
export const getErrors = (state: RootState) => state.referralLeads.errors?.details;
export const getCurrentPage = (state: RootState) => state.referralLeads.currentPage;

export const { setCurrentPage, clearState, } = referralLeadsSlice.actions;

export default referralLeadsSlice.reducer;
