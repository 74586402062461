import { FC, ReactNode, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useAppSelector } from "../../store/hooks";
import { isAuthenticated, IsAuthenticated } from "../../store/slices/auth";
import MuiBackdrop from "../../components/Backdrop";


interface BaseLayoutProps {
    children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
    const result = useAppSelector(isAuthenticated);
    const navigate = useNavigate();

    useEffect(() => {
        if (result === IsAuthenticated.AUTH) {
            navigate('/dashboard/houses');
        }
    }, [result])
    return (
        result === IsAuthenticated.GUEST ?
            <Box
                sx={{
                    flex: 1,
                    height: '100%',
                }}
            >
                {children || <Outlet />}
            </Box>
            : result === IsAuthenticated.PENDING ? <MuiBackdrop /> : <div> </div>
    );
};

BaseLayout.propTypes = {
    children: PropTypes.node,
};

export default BaseLayout;
