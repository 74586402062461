import { sendRequest } from '../api/axios';
import { IPagination } from "../interfaces";
import { IMove, MoveStatus } from '../models/move.model';


/**
 * @param query
 */
export const getAll = async (query: IPagination) => {
    return sendRequest({
        method: 'GET',
        params: query,
        url: '/moves',
    });
};

export const getAllLeads = async (query: IPagination) => {
    return sendRequest({
        method: 'GET',
        params: query,
        url: '/admin/leads',
    });
};

export const getFreeMoves = async (query: IPagination) => {
    return sendRequest({
        method: 'GET',
        params: query,
        url: '/moves/free',
    });
};

export const getLoadBoard = async (query: IPagination) => {
    return sendRequest({
        method: 'GET',
        params: query,
        url: '/admin/jobs',
    });
};

export const getClosedMovesRequest = async (query: IPagination) => {
    return sendRequest({
        method: 'GET',
        params: { ...query },
        url: '/moves/finished',
    });
};

export const getAccepted = async (query: IPagination) => {
    return sendRequest({
        method: 'GET',
        params: query,
        url: '/moves/accepted',
    });
};

export const aproveMove = async (id: string, data: { accept: boolean, bookId: string }) => {
    return sendRequest({
        method: 'POST',
        url: `/moves/${id}/accept`,
        data
    });
};

export const getOne = async (id: string): Promise<{ move: IMove, price: { total: number } }> => {
    return sendRequest({
        method: 'GET',
        url: `/moves/${id}/info`,
    });
};

export const getStatistic = async () => {
    return sendRequest({
        method: 'GET',
        url: `/moves/statistic`,
    });
};


export const updateMoveStatusRequest = async (data: { moveId: string; bookId: string; status: MoveStatus }) => sendRequest({
    method: 'PUT',
    url: `${data.moveId}/book`,
    data
})
