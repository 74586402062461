import { useEffect, useState } from 'react';

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  Stack,
  Pagination,
  Box,
  Button
} from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { paginationLimit } from '../../../constants';
import {
  getAllReferralPartnersThunk,
  getCurrentPage,
  getReferralPartners,
  getTotal,
  setCurrentPage
} from '../../../store/slices/referralPartner';
import { format } from 'date-fns';
import { client } from 'src/api/axios';
import { downloadFileFromBlob } from 'src/utils/file';
import Filters from 'src/components/FIlters/Filters';
import { referralPartnersBoardFiltersFields } from './data';
import { formatPhoneNumber } from 'src/utils/helpers';
import { formatDateWithTimezone } from '../../../utils/formatDateWithTimezone';
import move from '../../../store/slices/move';

const List = () => {
  const dispatch = useAppDispatch();
  const total = useAppSelector(getTotal);
  const currentPage = useAppSelector(getCurrentPage);
  const referralPartners = useAppSelector(getReferralPartners);

  const [pageCount, setPageCount] = useState<number>(0);
  const [filters, setFilters] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    setPageCount(Math.ceil(total / paginationLimit));
  }, [total]);

  const handlePageChange = (event: any, page: number) =>
    dispatch(setCurrentPage(page));

  const fetchReferralPartners = () => {
    const newOffset = (currentPage - 1) * paginationLimit;
    dispatch(setCurrentPage(currentPage));
    dispatch(
      getAllReferralPartnersThunk({
        limit: paginationLimit,
        offset: newOffset,
        ...filters
      })
    );
  };

  const handleDownload = async () => {
    const { data } = await client.get('/admin/referral-partners/download', {
      responseType: 'blob'
    });

    downloadFileFromBlob(data, 'Referral Partners');
  };

  const handleFiltersChange = (key: string, value: string) =>
    setFilters({ ...filters, [key]: value, orderBy: 'companyName' });

  useEffect(() => {
    fetchReferralPartners();
  }, [filters, currentPage]);

  // const handleDeleteUser = (id: string) => {
  //   if (!confirm("Are you sure?")) {
  //     return
  //   }
  //   dispatch(deleteUserThunk(id)).unwrap().then(fetchUsers)
  // }

  return (
    <Card>
      <Filters
        fields={referralPartnersBoardFiltersFields}
        filters={filters}
        setFilters={setFilters}
        handleDownload={handleDownload}
        handleFiltersChange={handleFiltersChange}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell align="center">Created</TableCell>
              {/* <TableCell align="right">Actions</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {referralPartners.map((partner) => {
              return (
                <TableRow hover key={partner?.id}>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {partner?.username}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {partner?.companyName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {partner?.user?.email}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {formatPhoneNumber(partner?.phone)}
                    </Typography>
                  </TableCell>

                  <TableCell align="center">
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {partner?.createdAt
                        ? formatDateWithTimezone(partner.createdAt)
                        : ''}
                    </Typography>
                  </TableCell>

                  {/* <TableCell align="right">
                    <Tooltip title="Edit User" arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter,
                          },
                          color: theme.palette.primary.main,
                        }}
                        color="inherit"
                        onClick={() => navigate(`/dashboard/clients/${user.id}/edit`)}
                        size="small"
                      >
                        <ViewIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete user" arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.error.lighter
                          },
                          color: theme.palette.error.main
                        }}
                        onClick={() => handleDeleteUser(user.id)}
                        color="inherit"
                        size="small"
                      >
                        <DeleteTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {total ? (
        <>
          <Box p={2} alignItems="center" justifyContent="center">
            <Stack spacing={2}>
              <Pagination
                count={pageCount}
                page={currentPage}
                color="primary"
                onChange={handlePageChange}
              />
            </Stack>
          </Box>
        </>
      ) : (
        ''
      )}
    </Card>
  );
};

export default List;
