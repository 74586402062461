import { sendRequest } from '../api/axios';

export interface Login {
  email: string;
  password: string;
}

export interface IUpdateProfileInfo {
  email: string,
  username: string
}

export interface IUpdateProfilePassword {
  current: string,
  new: string
  confirm: string
}


/**
 * @param data
 */
export const login = async (data: Login) => {
  return sendRequest({
    method: 'POST',
    url: '/auth/login',
    data: { ...data, role: 'admin' },
  });
};

export const logout = async () => {
  return sendRequest({
    method: 'POST',
    url: '/auth/signOut',
  });
};

export const getProfileInfo = async () => {
  return sendRequest({
    method: 'GET',
    url: '/profile',
  });
};

export const updateProfileInfo = async (data: IUpdateProfileInfo) => {
  return sendRequest({
    method: 'PUT',
    url: '/auth/profile',
    data
  });
};

export const updateProfilePassword = async (data: IUpdateProfilePassword) => {
  return sendRequest({
    method: 'PUT',
    url: '/auth/password',
    data
  });
};