export const getImagePath = (path: string) => `${process.env.REACT_APP_AMAZON_S3_CDN_URL}/${path}`;
export const constants = {
  ACCESS_TOKEN_KEY: 'ACCESS',
  REFRESH_TOKEN_KEY: 'REFRESH',
};

export const ErrorMessages = {
    INVALID_USER: 'INVALID_USER',
    INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
    RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
    INVALID_TOKEN: 'INVALID_TOKEN',
    ACCESS_DENIED: 'ACCESS_DENIED',
    UNAUTHORIZED: 'UNAUTHORIZED',
    SERVER_ERROR: 'SERVER_ERROR',
    TOKEN_EXPIRED_ERROR: 'TokenExpiredError',
    JSON_WEB_TOKEN_ERROR: 'JsonWebTokenError',

    IMAGE_REQUIRED: 'Image is required', 
    TITLE_REQUIRED: 'Title is required', 
    STATUS_REQUIRED: 'Status is required',
    USERNAME_REQUIRED: 'Username is required',
    MAIL_REQUIRED: 'Mail is required',

    DESCRIPTION_REQUIRED: 'Description is required',
    PRICE_REQUIRED: 'Price is required',
    FIELD_REQUIRED: 'Field is required',
    FIELDS_MATCH: 'Fields should be match',
    DATE_VALID: 'Date should be valid',
    DATE_NOT_VALID: 'Date is not valid',


    PASSWORD_REQUIRED: 'Password is required',
    CURRENT_PASS_REQUIRED: 'Current Password is required',
    NEW_PASS_REQUIRED: 'New Password is required',
    CONFIRM_PASS_REQUIRED: 'Confirm Password is required',
    PASSWORDS_MATCH: 'Confirm Password is required',
    CUBIC_FEET_REQUIRED: 'Cubic Feet is required, should be a positive number',
    MIN_CHARACTER: 'Title must be at least 3 character',
    MAX_CHARACTER: 'Title be less than 255 characters',
    MIN_ARGUMENT: 'Should have minimum 1 argument',
    MAX_VALUE: 'Max value 1000',

};

export enum ActionType {
   create= 'create',
   update= 'update',
}

export enum AwsExtensions {
  jpeg = 'jpeg',
  jpg = 'jpg',
  png = 'png',
  webp = 'webp',
}

export const FileTypes = [ 'jpeg', 'jpg', 'png', 'webp' ]
export const getFileTypeErrorMessage = (type: string) => `Invalid type ${type} , Allowed only ${FileTypes.join(',')} extension`;

export const paginationLimit = 10;
export const paginationOffset = 0;

