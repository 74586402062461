import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PartnerModel, PartnerStatus } from '../../models/partner.model';
import {
  getAll,
  updateStatus,
  getOne,
  getAccessToken,
  deletePartnerRequest,
  changeActiveStatusRequest
} from '../../services/partner.service';
import { GetAllPartnersQuery } from '../../interfaces/partners';
import { RootState } from '../index';

export interface PartnerState {
  data: PartnerModel[];
  partner?: PartnerModel;
  total: number;
  currentPage: number;
}

const initialState: PartnerState = {
  data: [],
  partner: null,
  total: 0,
  currentPage: 1
};

export const getAllPartners = createAsyncThunk(
  'partners/getAll',
  async (query: GetAllPartnersQuery) => getAll(query)
);

export const getPartnerDraftUrl = createAsyncThunk(
  'partners/getAccessToken',
  async (partnerId: number) => getAccessToken(partnerId)
);

export const getOnePartners = createAsyncThunk(
  'partners/getOne',
  async (id: string) => getOne(id)
);

export const updatePartnerStatus = createAsyncThunk(
  'partners/updateStatus',
  async ({ id, status }: { id: string; status: PartnerStatus }) => {
    return updateStatus(id, status);
  }
);

export const deletePartner = createAsyncThunk(
  'partners/delete',
  async ({ id }: { id: string }) => {
    return deletePartnerRequest(id);
  }
);

export const changeActiveStatus = createAsyncThunk(
  'partners/changeActiveStatus',
  async ({ id, isActive }: { id: number; isActive: boolean }) => {
    return changeActiveStatusRequest(id, isActive);
  }
);

const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    resetPartnersData: (state) => {
      state.currentPage = initialState.currentPage;
      state.total = initialState.total;
      state.data = initialState.data;
    },
    clearState: (state) => {
      state.data = [];
      state.partner = null;
      state.total = 0;
      state.currentPage = 1;
    }
  },
  extraReducers: (builder) => {
    /** GET ALL * */
    builder.addCase(
      getAllPartners.fulfilled,
      (state, { payload: { data, total } }) => {
        state.data = data;
        state.total = total;
      }
    );

    builder.addCase(getOnePartners.fulfilled, (state, { payload }) => {
      state.partner = payload;
    });
  }
});

export const { setCurrentPage, resetPartnersData, clearState } =
  partnerSlice.actions;
export const getPartner = (state: RootState) => state.partner.partner;

export default partnerSlice.reducer;
