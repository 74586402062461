import { sendRequest } from '../api/axios';
import { IPagination } from '../interfaces';
import { PartnerStatus } from '../models/partner.model';
import { GetAllPartnersQuery } from '../interfaces/partners';

export const updateStatus = async (id: string, status: PartnerStatus) => {
  return sendRequest({
    method: 'PUT',
    url: `/partners/${id}/status`,
    data: { status }
  });
};

export const getAll = async (query: GetAllPartnersQuery) => {
  return sendRequest({
    method: 'GET',
    params: query,
    url: '/admin/partners'
  });
};

export const getAccessToken = async (partnerId: number) => {
  return sendRequest({
    method: 'GET',
    url: `/admin/partners/${partnerId}/access-token`
  });
};

export const getOne = async (id: string) => {
  return sendRequest({
    method: 'GET',
    url: `/partners/${id}/info`
  });
};

export const deletePartnerRequest = async (id: string) => {
  return sendRequest({
    method: 'DELETE',
    url: `/partners/${id}`
  });
};
export const changeActiveStatusRequest = async (
  id: number,
  isActive: boolean
) => {
  return sendRequest({
    method: 'PUT',
    data: { isActive },
    url: `/partners/${id}/isActive`
  });
};
