import { useRef, useState } from 'react';

import {
  alpha,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { formatDistance, subDays } from 'date-fns';
import { useAppSelector } from '../../../../../store/hooks';
import { getUnreadNotifications } from '../../../../../store/slices/notification';
import { StyledNotificationsBadge } from './styled';
import { useNavigate } from 'react-router';
import { NotificationModel, NotificationType } from '../../../../../models/notification.model';
import { stringConverter } from '../../../../../helpers/stringConverter';

export const getNotificationText = (notification: NotificationModel) => {
  switch (notification.type) {
    case NotificationType.NEW_MOVE:
      return ` MoveId: ${notification.id}`;
    case NotificationType.NEW_USER:
      return ` UserId: ${notification.id}`;
    case NotificationType.NEW_PARTNER:
      return ` Partner: ${notification.id}`;
  }
};

function HeaderNotifications() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const unread = useAppSelector(getUnreadNotifications);
  const navigate = useNavigate();

  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton ref={ref} onClick={() => setOpen(true)}>
          <StyledNotificationsBadge
            badgeContent={unread?.total}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <NotificationsNoneIcon />
          </StyledNotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">Notifications</Typography>
        </Box>
        <Divider />
        <List sx={{ p: 0 }}>
          {unread?.data.length ? (
            unread?.data.map((notification) => {
              return (
                <ListItem
                  key={notification.id}
                  sx={{
                    p: 2,
                    minWidth: 350,
                    display: { xs: 'block', sm: 'flex' },
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: alpha('#000', 0.05)
                    }
                  }}
                  onClick={() => {
                    setOpen(false);
                    navigate(`/dashboard/notifications`);
                  }}
                >
                  <Box flex="1">
                    <Box display="flex" justifyContent="space-between">
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {stringConverter(notification.type)}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ textTransform: 'none' }}
                      >
                        {formatDistance(
                          subDays(new Date(notification.createdAt), 0),
                          new Date(),
                          {
                            addSuffix: true
                          }
                        )}
                      </Typography>
                    </Box>
                    <Typography
                      component="span"
                      variant="body2"
                      color="text.secondary"
                    >
                      {getNotificationText(notification)}
                    </Typography>
                  </Box>
                </ListItem>
              );
            })
          ) : (
            <ListItem>
              <Typography color={alpha('#000', 0.7)}>
                No unread messages
              </Typography>
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
}

export default HeaderNotifications;
