import { sendRequest } from '../api/axios';
import {IPagination} from "../interfaces";

export interface ICreateInsurance {
    title: string;
    description: string;
    price: number;
}

export interface IUpdateInsurance extends  ICreateInsurance {
    isActive: boolean;
}

/**
 * @param data
 */
export const create = async (data: ICreateInsurance) => {
    return sendRequest({
        method: 'POST',
        url: '/insurances',
        data,
    });
};

export const update = async (id: string, data: IUpdateInsurance) => {
    return sendRequest({
        method: 'PUT',
        url: `/insurances/${id}`,
        data
    });
};

export const updateDefault = async (id: string) => {
    return sendRequest({
        method: 'PUT',
        url: `/insurances/${id}/default`,
    });
};

export const destroy = async (id: string) => {
    return sendRequest({
        method: 'DELETE',
        url: `/insurances/${id}`,
    });
};

export const getAll = async (query: IPagination) => {
    return sendRequest({
        method: 'GET',
        params: query,
        url: '/insurances',
    });
};

export const getOne = async (id: string) => {
    return sendRequest({
        method: 'GET',
        url: `/insurances/${id}/info`,
    });
};

