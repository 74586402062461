import { IPagination, ISearch } from '../interfaces/index';
import { sendRequest } from '../api/axios';

export interface ICreateHouse {
  image: string
  isActive: boolean
  title: string
  rooms: IAddRoom[]
}
interface IUpdateHouse extends ICreateHouse { }
interface IAddRoom {
  id: string,
  count: number
}
export interface IAddRooms {
  id: string;
  rooms: IAddRoom[];
}

/**
 * @param data
 */

export const create = async (data: ICreateHouse) => {
  return sendRequest({
    method: 'POST',
    url: '/houses',
    data,
  });
};

export const update = async (id: string, data: IUpdateHouse) => {
  return sendRequest({
    method: 'PATCH',
    url: `/admin/houses/${id}`,
    data,
  });
};

export const addRooms = async (data: IAddRooms) => {
  return sendRequest({
    method: 'POST',
    url: `/houses/${data.id}/rooms`,
    data: {
      rooms: data.rooms
    }
  });
};

export const destroy = async (id: string) => {
  return sendRequest({
    method: 'DELETE',
    url: `/houses/${id}`,
  });
};

export const getAll = async (query: IPagination) => {
  return sendRequest({
    method: 'GET',
    params: query,
    url: '/houses',
  });
};

export const getOne = async (id: string) => {
  return sendRequest({
    method: 'GET',
    url: `/admin/houses/${id}`,
  });
};

export const search = async (key: ISearch) => {
  return sendRequest({
    method: 'GET',
    params: key,
    url: `/houses/search`,
  });
};