import { useEffect, useState } from 'react';

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  Stack,
  Pagination,
  Box,
  Button
} from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { paginationLimit } from '../../../constants';
import {
  getAllReferralLeadsThunk,
  getCurrentPage,
  getReferralLeads,
  getTotal,
  setCurrentPage
} from '../../../store/slices/referralLeads';
import { format } from 'date-fns';
import { client } from 'src/api/axios';
import { downloadFileFromBlob } from 'src/utils/file';
import { formatPhoneNumber } from 'src/utils/helpers';
import { formatDateWithTimezone } from '../../../utils/formatDateWithTimezone';

const List = () => {
  const currentPage = useAppSelector(getCurrentPage);
  const referralLeads = useAppSelector(getReferralLeads);
  const total = useAppSelector(getTotal);
  const [pageCount, setPageCount] = useState<number>(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setPageCount(Math.ceil(total / paginationLimit));
  }, [total]);

  const handlePageChange = (event: any, page: number) =>
    dispatch(setCurrentPage(page));

  const fetchReferralLeads = () => {
    const newOffset = (currentPage - 1) * paginationLimit;
    dispatch(setCurrentPage(currentPage));
    dispatch(
      getAllReferralLeadsThunk({ limit: paginationLimit, offset: newOffset })
    );
  };

  const handleDownload = async () => {
    const { data } = await client.get('/admin/referrals/download', {
      responseType: 'blob',
      params: {
        download: true
      }
    });

    downloadFileFromBlob(data, 'Referral Leads');
  };

  useEffect(() => {
    fetchReferralLeads();
  }, [currentPage]);

  return (
    <Card>
      <Box
        sx={{
          textAlign: 'right',
          padding: '20px'
        }}
      >
        <Button variant="contained" onClick={handleDownload}>
          Download
        </Button>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {/* <TableCell>Company</TableCell> */}
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell align="center">Referred By</TableCell>
              <TableCell align="center">Created</TableCell>
              {/* <TableCell align="right">Actions</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {referralLeads.map((customer) => {
              return (
                <TableRow hover key={customer?.id}>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {customer?.username}
                    </Typography>
                  </TableCell>
                  {/* <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {customer?.companyName}
                    </Typography>

                  </TableCell> */}
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {customer?.email}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {formatPhoneNumber(customer?.phone)}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {customer?.partner?.username}
                    </Typography>
                  </TableCell>

                  <TableCell align="center">
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {customer?.createdAt
                        ? formatDateWithTimezone(customer.createdAt)
                        : ''}
                    </Typography>
                  </TableCell>

                  {/* <TableCell align="right">
                    <Tooltip title="Edit User" arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter,
                          },
                          color: theme.palette.primary.main,
                        }}
                        color="inherit"
                        onClick={() => navigate(`/dashboard/clients/${user.id}/edit`)}
                        size="small"
                      >
                        <ViewIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete user" arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.error.lighter
                          },
                          color: theme.palette.error.main
                        }}
                        onClick={() => handleDeleteUser(user.id)}
                        color="inherit"
                        size="small"
                      >
                        <DeleteTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {total ? (
        <>
          <Box p={2} alignItems="center" justifyContent="center">
            <Stack spacing={2}>
              <Pagination
                count={pageCount}
                page={currentPage}
                color="primary"
                onChange={handlePageChange}
              />
            </Stack>
          </Box>
        </>
      ) : (
        ''
      )}
    </Card>
  );
};

export default List;
