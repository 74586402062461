import { sendRequest } from '../api/axios';

/**
 * @param data
 */

export const callStates = async () => {
  return sendRequest({
    method: 'GET',
    url: '/states'
  });
};


export const callHouses = async () => {
  return sendRequest({
    method: 'GET',
    url: '/houses'
  });
};