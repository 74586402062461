import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAll, getOne } from '../../services/message.service';
import { MessageModel } from '../../models/message.model';
import { IPagination } from '../../interfaces';

export interface MessageState {
  data: MessageModel[];
  message?: MessageModel;
  total: number;
  currentPage: number;
}

const initialState: MessageState = {
  data: [],
  total: 0,
  currentPage: 1
};

export const getAllMessages = createAsyncThunk(
  'messages/getAll',
  async (query: IPagination) => getAll(query)
);

export const getOneMessage = createAsyncThunk(
  'messages/getOne',
  async (id: string) => getOne(id)
);

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    clearMessagesState: (state) => {
      state.currentPage = initialState.currentPage;
      state.total = initialState.total;
      state.data = initialState.data;
      state.message = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMessages.fulfilled, (state, { payload: { data, total } }) => {
      state.data = data;
      state.total = total;
    });
    builder.addCase(getOneMessage.fulfilled, (state, { payload }) => {
      state.message = payload;
    });
  }
});

export const { setCurrentPage, clearMessagesState } = messageSlice.actions;

export default messageSlice.reducer;
