import { sendRequest } from '../api/axios';
import {IPagination, ISearch} from "../interfaces";

export interface ICreateInventory {
    title: string;
    isActive?: boolean;
}

export interface IUpdateInventory extends  ICreateInventory {}

/**
 * @param data
 */
export const create = async (data: ICreateInventory) => {
    return sendRequest({
        method: 'POST',
        url: '/inventories/create',
        data,
    });
};

export const update = async (id: string, data: IUpdateInventory) => {
    return sendRequest({
        method: 'PUT',
        url: `/inventories/${id}`,
        data
    });
};

export const destroy = async (id: string) => {
    return sendRequest({
        method: 'DELETE',
        url: `/inventories/${id}`,
    });
};

export const getAll = async (query: IPagination) => {
    return sendRequest({
        method: 'GET',
        params: query,
        url: '/inventories',
    });
};

export const getOne = async (id: string) => {
    return sendRequest({
        method: 'GET',
        url: `/inventories/${id}/info`,
    });
};

export const search = async (key: ISearch) => {
    return sendRequest({
        method: 'GET',
        params: key,
        url: `/inventories/search`,
    });
};