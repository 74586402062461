import { useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import { List, Button, ListItem } from '@mui/material';
import { SidebarContext } from '../../../../contexts/SidebarContext';
import { StyledMenuWrapper, StyledSubMenuWrapper } from './styled';

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);

  return (
    <StyledMenuWrapper>
      {/* <List component="div">
        <StyledSubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard"
              >
                Dashboard
              </Button>
            </ListItem>
          </List>
        </StyledSubMenuWrapper>
      </List> */}
      <List>
        <StyledSubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/houses"
              >
                Houses Sizes
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/job-board/active"
              >
                Job Board (Active)
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/job-board/passed"
              >
                Job Board (Passed)
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/quoted-job"
              >
                Quoted Jobs
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/customers"
              >
                Customers
              </Button>
            </ListItem>
            {/* <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/rooms"
                startIcon={<RoomIcon />}
              >
                Rooms
              </Button>
            </ListItem> */}

            {/* <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/inventories"
                startIcon={<InventoryIcon />}
              >
                Inventories
              </Button>
            </ListItem> */}

            {/* <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/boxes"
                startIcon={<BoxIcon />}
              >
                Boxes
              </Button>
            </ListItem> */}

            {/* <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/insurances"
                startIcon={<InsuranceIcon />}
              >
                Insurances
              </Button>
            </ListItem> */}

            {/* <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/leads/interstate"
                startIcon={<MoveIcon />}
              >
                Leads - interstate
              </Button>
            </ListItem> */}
            {/* <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/leads/instate"
                startIcon={<MoveIcon />}
              >
                Leads - instate
              </Button>
            </ListItem> */}

            {/* <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/load-board/interstate"
                startIcon={<MoveIcon />}
              >
                Load board - interstate
              </Button>
            </ListItem> */}
            {/* <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/load-board/instate"
                startIcon={<MoveIcon />}
              >
                Load board - instate
              </Button>
            </ListItem> */}

            {/* <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/accepted-moves/interstate"
                startIcon={<MoveIcon />}
              >
                Accepted Jobs - interstate
              </Button>
            </ListItem> */}

            {/* <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/accepted-moves/instate"
                startIcon={<MoveIcon />}
              >
                Accepted Jobs - instate
              </Button>
            </ListItem> */}

            {/* <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/closed-jobs"
                startIcon={<MoveIcon />}
              >
                Closed jobs
              </Button>
            </ListItem> */}

            {/* <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/settings"
                startIcon={<SettingsIcon />}
              >
                Settings
              </Button>
            </ListItem> */}

            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/referral-partners"
              >
                Referral partners
              </Button>
            </ListItem>

            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/referral-leads"
              >
                Referral Portal Leads
              </Button>
            </ListItem>

            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/partners/active"
              >
                Moving Partners (Active)
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/partners/draft"
              >
                Moving Partners (Draft)
              </Button>
            </ListItem>

            {/* <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/approved-partners"
                startIcon={<PeopleIcon />}
              >
                Approved partners
              </Button>
            </ListItem> */}

            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/messages"
              >
                Messages
              </Button>
            </ListItem>

            {/* <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/notifications"
              >
                Notifications
              </Button>
            </ListItem> */}

            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/transactions"
              >
                Transactions
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/dashboard/connections"
              >
                Connections
              </Button>
            </ListItem>

          </List>
        </StyledSubMenuWrapper>
      </List>
    </StyledMenuWrapper>
  );
}

export default SidebarMenu;
