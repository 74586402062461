import { sendRequest, clientS3 } from '../api/axios';
import { SettingsUpdateModel } from '../models/setting.model';

export interface IGetAwsConfig {
    extension: 'jpeg' | 'jpg' | 'png' | 'webp'
}

export const getAwsConfig = (query: IGetAwsConfig) => {
    return sendRequest({
        method: 'GET',
        url: '/settings/s3',
        params: query,
    });
}

export const uploadImage = (url: string, file: any ) => {
    return clientS3.put(url, file);
}

export const getSettings = () => {
    return sendRequest({
        method: 'GET',
        url: '/settings',
    });
}

export const updateSettings = (data: SettingsUpdateModel ) => {
    return sendRequest({
        method: 'POST',
        url: '/settings',
        data,
    });
}

export const getSettingsByKey = (key: string) => {
    return sendRequest({
        method: 'GET',
        url: `/settings/${key}/key`,
    });
}
