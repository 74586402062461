import { sendRequest } from '../api/axios';
import { IPagination } from "../interfaces";
import { IMove, MoveStatus } from '../models/move.model';


/**
 * @param query
 */

export const getAllAcceptedLeads = async (query: IPagination) => {
    // here
    return sendRequest({
        method: 'GET',
        params: query,
        url: '/admin/connections',
    });
};
