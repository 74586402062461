import { useEffect, useRef, useState } from 'react';

import { NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';

import { getUser, logoutThunk, simpleLogout } from '../../../../store/slices/auth';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
        
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.colors.alpha.black[100]};
        display: block;
`
);

function HeaderUserbox() {
  const user = useAppSelector(getUser);

  useEffect(() => { }, [user]);

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const dispatch = useAppDispatch();

  const logeOut = () => {
    // dispatch(logoutThunk());
    dispatch(simpleLogout())
  };

  return (
    user && (
      <>
        <UserBoxButton ref={ref} onClick={handleOpen}>
          <Hidden mdDown>
            <UserBoxText>
              <UserBoxLabel variant="subtitle2">{user.username}</UserBoxLabel>
            </UserBoxText>
          </Hidden>
          <Hidden smDown>
            <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
          </Hidden>
        </UserBoxButton>
        <Popover
          anchorEl={ref.current}
          onClose={handleClose}
          open={isOpen}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MenuUserBox sx={{ minWidth: 210 }} display="flex">
            <UserBoxText>
              <UserBoxLabel variant="subtitle2">{user.username}</UserBoxLabel>
              <UserBoxLabel variant="body1" sx={{ opacity: '.6' }}>{user.email}</UserBoxLabel>
            </UserBoxText>
          </MenuUserBox>
          <Divider sx={{ mb: 0 }} />
          <List sx={{ p: 1 }} component="nav">
            <ListItem
              button
              to="/dashboard/account"
              component={NavLink}
            >
              <AccountTreeTwoToneIcon fontSize="small" />
              <ListItemText primary="Account" />
            </ListItem>
          </List>
          <Divider />
          <Box sx={{ m: 1 }}>
            <Button color="primary" fullWidth onClick={logeOut}>
              <LockOpenTwoToneIcon sx={{ mr: 1 }} />
              Sign out
            </Button>
          </Box>
        </Popover>
      </>
    )
  );
}

export default HeaderUserbox;
