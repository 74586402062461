import { format, toDate, utcToZonedTime } from 'date-fns-tz';

export const formatDateWithTimezone = (
  dateString: Date | string,
  timezone: string = 'America/New_York',
  formatString: string = 'MM.dd.yyyy'
): string => {
  const date = toDate(dateString, { timeZone: timezone });
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date string');
  }

  const utcDate = utcToZonedTime(date, timezone);
  return format(utcDate, formatString, { timeZone: timezone });
};
