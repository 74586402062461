import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


import { RootState } from '../index';
import { getAllReferralPartners } from 'src/services/referralPartner.service';
import { ReferralPartnerModel } from 'src/models/referralPartner.model';
import { IPagination } from 'src/interfaces';



export interface UserState {
  partners: ReferralPartnerModel[];
  total: number;
  currentPage: number;
  errors: any;
}

const initialState: UserState = {
  partners: [],
  total: 0,
  currentPage: 1,
  errors: null,
};


export const getAllReferralPartnersThunk = createAsyncThunk(
  'user/getAll',
  async (query: IPagination) => getAllReferralPartners(query)
);

// export const getOneThunk = createAsyncThunk('user/getOne', async (id: string) =>
//   getOneReferralPartner(id)
// );

// export const deleteUserThunk = createAsyncThunk(
//   'user/delete',
//   async (id: string) => deleteReferralPartner(id)
// );

const referralPartnerSlice = createSlice({
  name: 'referralPartner',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    clearState: (state) => {
      state.partners = [];
      state.total = 0;
      state.currentPage = 1;
      state.errors=null;
    },

  },
  extraReducers: (builder) => {

    /** GET ALL PARTNERS * */
    builder.addCase(getAllReferralPartnersThunk.fulfilled, (state, action) => {
      state.partners = action.payload.data;
      state.total = action.payload.total;
    });
      builder.addCase(getAllReferralPartnersThunk.rejected, (state, action) => {
      state.errors = action.payload;
    });

  }
});

export const getReferralPartners = (state: RootState) => state.referralPartner.partners;
export const getTotal = (state: RootState) => state.referralPartner.total;
export const getErrors = (state: RootState) => state.referralPartner.errors?.details;
export const getCurrentPage = (state: RootState) => state.referralPartner.currentPage;

export const { setCurrentPage, clearState,} =  referralPartnerSlice.actions;

export default referralPartnerSlice.reducer;
