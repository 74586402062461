import { sendRequest } from '../api/axios';
import { IPagination } from '../interfaces/index';




export const getAllReferralPartners = async (query: IPagination) => {
  return sendRequest({
    method: 'GET',
    params: query,
    url: '/admin/referral-partners',
  });
};



// export const getOneReferralPartner = async (id: string) => {
//   return sendRequest({
//       method: 'GET',
//       url: ``,
//   });
// };


// export const deleteReferralPartner = async (id: string) => sendRequest({
//   url: ``,
//   method: 'DELETE',
// });