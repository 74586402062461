import axios from 'axios';

import { getToken } from '../helpers/token';
import { ErrorMessages, constants } from "../constants";
import { removeToken } from '../helpers/token';
import { toast } from "react-toastify";

class Exception extends Error {
    private readonly details: any | null;

    constructor(name: string, details: any = null) {
        super(name);
        Object.setPrototypeOf(this, new.target.prototype);
        this.details = details;
    }
}


interface IParams {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
    url: string,
    data?: any,
    params?: any
}

export const client = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
    baseURL: process.env.REACT_APP_API_URL,
});

export const clientS3 = axios.create({
    headers: {
        'Content-Type': 'application/octet-stream'
    },
    withCredentials: false
});

export const init = () => {
    const jwtToken = getToken();
    if (jwtToken) {
        client.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
    }
};

/**
 * @param params
 */
export const sendRequest = async (params: IParams) => {


    try {
        const result = await client.request({ ...params });

        if (params.method !== 'GET') {
            toast("SUCCESS", { type: 'success', autoClose: 2000 });
        }

        return result.data;
    } catch (err: any) {
        const { details, error } = err.response.data;
        if ([ErrorMessages.UNAUTHORIZED, ErrorMessages.JSON_WEB_TOKEN_ERROR, ErrorMessages.TOKEN_EXPIRED_ERROR].includes(error)) {
            removeToken();
            removeToken(constants.REFRESH_TOKEN_KEY);
        }
        throw new Exception(error, details);
    }
};



export const axiosInstance = client;
